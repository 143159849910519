.section-page-content {
    max-width: 1366px;
    width: 100%;
    margin: 0 auto;
}

.w-section-block {
    margin-bottom: 60px;
}

.section-page-content .ytp-controls-bar {
    z-index: 4;
}